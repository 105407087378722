import { E_AD_CATEGORY_NAME } from '@common/enums';
import type { TCreative } from '@common/types';

export const ADS_COLLECTION_WEBSITE: TCreative[] = [
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: '7anG-FcGu-jCbu',
    title: 'Vertical Video',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/landrover-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: 'bRgX-cnLe-EZu4',
    title: 'Blurred Background',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/BMW_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_DISPLAY_SOLUTION,
    slotId:
    'CuZV-huLy-FRIX+2yKF-IpFk-H7Oi+c7eg-Fvhr-tvX3+dD37-1iI1-YLHt+ZbIG-q1h7-4czZ+9ZCr-0AWa-0JLg+kecK-Nw6G-f3zp',
    title: 'Drag & Drop + Tap',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/Goesser_Logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_DISPLAY_SOLUTION,
    slotId:
    'hhPU-DLLb-ffb1+Ojn1-xhad-LkNQ+xvdm-HtOM-DDgo+xpq5-SSfh-bJ8p+WeIO-0hES-fB5g+eRIx-yg20-L8om+UUy2-zhng-w1RB',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/McDonalds_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'Pdyb-hNa2-97p5',
    title: 'Drag & Drop',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/Malfy_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'pq7x-8FhW-I7wa',
    title: 'Map Integration',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/subway_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'ULXc-Jc4j-CLd6',
    title: 'Incoming Call',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/LEGO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'dZ6U-Ol8B-aUMH',
    title: 'Mini-Game',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/NESPRESSO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'FE34-wE1b-VfRw',
    title: 'Panorama',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/HASBRO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'coKJ-CANA-QMo5',
    title: 'Hot Spots',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/porsche-logo-svgrepo-com.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'fHsN-8Yzw-IKPO',
    title: '360 Swipe',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/Kia_biale.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'FLEi-ufjC-FRwA',
    title: 'Augmented Reality',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/SiemensLogoBiale.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '3ydm-9UQn-zB30',
    title: 'Poll',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/HBO_Max_Logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'uQ7m-LLVI-AQvG',
    title: 'Story',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/CARLOLINA-HERRERA.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'fKKp-eDBc-lwHE',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/FUJIFILM.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: '64Ou-tDTZ-M9Fx',
    title: 'Reactive Scroll',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/skoda-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'GTn7-z9Qo-bN67',
    title: 'Slider',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/logo-bionorica.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'aUN3-j4A0-LbZj',
    title: 'Reactive Scroll',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/McDonalds_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: 'FLET-F0X1-LFXk',
    title: 'Blurred Background',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/samsung-logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: 'BdyT-wSuL-BAUF',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/donna-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: 'zWIi-9mVv-qmF5',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/BREUNINGER.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: '34Po-xrAJ-2pVM',
    title: 'Quiz',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/BMW_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'VLkU-ZwNN-cELZ',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/LANCOME.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: '2Aqf-9gXb-OhIz',
    title: 'Video Integration',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/Mercedes_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'nSZo-5B9v-T2WX',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/Logo_Rivella.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'Kotf-76Qs-HURZ',
    title: 'Mini-Game',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/logo-picadeli.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: '3GrG-pQ63-Bi93',
    title: 'Vertical Video',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/nivea-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: '0u1E-xMP2-4won',
    title: 'Quiz',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/VW.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'HnMT-mcJD-utS4',
    title: 'Slider',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/Sinebrychoff_Garage.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: '0mMQ-42uE-OMNV',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/ccc.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'f6Em-FIPE-yQl2',
    title: 'Wipe',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/Telia_Company_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'Sx6M-3jUL-gffc',
    title: 'Video Integration',
    displayMode: 'desktop',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/Milka_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'Au5x-A4I8-Kzn7+udnA-v9eH-hSuG',
    title: 'Gallery',
    displayMode: 'desktop',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/NESPRESSO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'ADnz-YDgE-qOoz+I2da-n8nB-6nwh',
    title: 'Video Integration',
    displayMode: 'desktop',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/logo-Club-Med.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'B4Bm-TctZ-109K+5qwm-6Jme-I0lQ',
    title: 'Scroll-Reactive',
    displayMode: 'desktop',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/lillet-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'xhCH-3xgX-TnKQ',
    title: 'Animation',
    displayMode: 'desktop',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/Magenta_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'cu7s-ojNz-IjGt',
    title: 'Tap',
    displayMode: 'desktop',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/toffifee_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'PWlB-eyua-ds25',
    title: 'Hot Spots',
    displayMode: 'desktop',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/XIAOMI.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: '0Yip-1hp7-s0PR',
    title: 'Scroll-Reactive',
    displayMode: 'desktop',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/ZKB_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'YnZj-5lCG-6nXb',
    title: 'Story',
    displayMode: 'desktop',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/BALVENIE.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'DGQS-xshB-iKLN',
    title: 'Vertical Video',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/orlen.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'GeDQ-mH1H-qQYn',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/nivea-logo.png',
  },
];
